import GenericMenu from "../vue/pages/generic-menu.page.vue";
import TermsAndConditions from "../vue/pages/terms-and-conditions.page.vue";
import MainMenu from "../vue/pages/main-menu.page.vue";
import Faq from "../vue/pages/faq.page.vue";
import HelpCenter from "../vue/pages/help-center.page.vue";

export const routes = [
  {
    url: "",
    scene: "main_menu",
    vuePage: MainMenu,
  },
  {
    url: "error",
    scene: "error",
  },
  {
    url: "terms-and-conditions",
    vuePage: TermsAndConditions,
    canReload: true,
  },
  {
    url: "faq",
    vuePage: Faq,
    canReload: true,
  },
  {
    url: "help-center",
    vuePage: HelpCenter,
    canReload: true,
  },
  {
    url: "placeholder",
    scene: "placeholder",
    canReload: true,
  },
  {
    url: ":category",
    scene: "generic_menu",
    vuePage: GenericMenu,
    canReload: true,
  },
  {
    url: ":category/:submenu",
    scene: "generic_menu",
    vuePage: GenericMenu,
    canReload: true,
  },
  {
    url: ":category/:submenu/:asset",
    scene: "generic_menu",
    vuePage: GenericMenu,
    canReload: true,
  },
  {
    url: ":category/:submenu/:id/model",
    scene: "model",
    canReload: true,
  },
  {
    url: ":category/:submenu/:id/video",
    scene: "video",
    canReload: true,
    requestOrientationPermission: true,
  },
];
