import { webstore } from "../helpers/auth-helpers";
import { config } from "../helpers/domain-config";
import { environmentConfig } from "../../environments/environment";

export const redirectToLogin = () => {
  const url = getLoginURL();
  webstore.delete();
  window.location.href = url;
};

export const getLoginURL = () => {
  const baseURL = new URL(
    `https://login.${config.productName}.com/${config.hash}`
  );
  const origin = window.location.origin;
  if (redirectOrigins.indexOf(origin) >= 0)
    baseURL.searchParams.append("redirect_uri", origin);
  return baseURL;
};

export const noAuthRequired = () => {
  const noAuthHosts = [
    "demo.xr-academy.com",
    "xr-academy.leunix.nl",
    "holozonia.xr-academy.com",
  ];
  return (
    environmentConfig.skipAuthentication ||
    noAuthHosts.indexOf(window.location.hostname) >= 0
  );
};
export const redirectToForgotPassword = () => {
  const url = `https://login.${config.productName}.com/${config.hash}#/forgot-password`;
  window.location.href = url;
};

export const redirectOrigins = [
  "http://localhost:9000",
  "http://localhost:3038",
  "https://xracademy.vidzmapping.be",
  "https://sandbox.leunix.nl",
  "http://sandbox.leunix.nl",
  "https://leunix-dev.eu.ngrok.io",
  "http://leunix-dev.eu.ngrok.io",
];
