import { config } from "../helpers/domain-config";
import { webstore } from "../helpers/auth-helpers";
/* eslint-disable max-len */
const UnsubLinks = {
  OM: "https://n.xr-academy.com/unsubscribe/om?country=om&domain=om.xr-academy.com",
  ZA: "https://n.xr-academy.com/unsubscribe/za/?country=za&domain=za.xr-academy.com",
  DE: "https://n.xr-academy.com/unsubscribe/de/?country=de&domain=de.xr-academy.com",
  CA: "https://n.mobiovr.com/unsubscribe/ca/?country=ca&domain=ca.mobiovr.com&platform=mcb",
  CZ: "https://n.xr-academy.com/unsubscribe/cz/?lang=cz",
  MY: "https://checksubscription.com/?c=my",
  QA: "https://n.xr-academy.com/unsubscribe/qa?lang=en",
  PS: "https://n.xr-academy.com/unsubscribe/ps?lang=ar",
  ES: "https://emocion.fonestarz.com/micuentamovil/",
};
/* eslint-enable max-len */

export const getUnsubLink = () => {
  const domain = config.subdomain.toUpperCase();
  const uid = webstore.getProperty("token");
  if (Object.keys(UnsubLinks).indexOf(domain) >= 0) {
    const appendToken = domain !== "ES";

    if (domain === "MY") {
      return UnsubLinks[domain];
    } else if (uid && appendToken) {
      return `${UnsubLinks[domain]}&token=${uid}`;
    } else {
      return UnsubLinks[domain];
    }
  }
  return null;
};
